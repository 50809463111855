import apis from "../../../apis/api.actions";
import { parseQueryParams } from "../../../utils/formValidate";

export const getCallDetails = (key) =>
  apis.getDatas(`/communication-call/${key}`).then((res) => res);
export const getCalls = (key) =>
  apis.getDatas(`/communication-call${parseQueryParams(key)}`).then((res) => res);
export const createCall = (data) =>
  apis.createData(`/communication-call`, data).then((res) => res);
export const updateCall = (id, data) =>
  apis.putPostData(`/communication-call/${id}`, data).then((res) => res);
export const deleteCall = (id) =>
  apis.deleteData(`/communication-call/${id}`).then((res) => res);
