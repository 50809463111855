import { Grid } from "@mui/material";
import Drawer from "@mui/material/Drawer";
// import Iconbutton from "../../IconButton";
// import SearchBoxSecondary from "../../secondarysearchBox";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import React, { Component } from "react";
import { FormatError } from "../../../../utils/formValidate";
import { getMessageForKey } from "../../../../utils/messages";
import ActionDialog from "../../../actiondialogs/actiondialog.component";
import CustomIcons from "../../../commonIcons";
import BlockingLoader from "../../../loader/blockingloader";
import SnackComp from "../../../snackbar/snack.component";
import CallDetailDrawer from "./callDetail";
import CallsModal from "../../../dynamicform/dynamicEntityCreateModal";
import { createCall, deleteCall, getCallDetails, getCalls, updateCall } from "../call.apis";
import "../calls_style.css";
import { getMappedValue, getProjectItem } from "../../../../utils/config";
import DynamicEntityModal from "../../../dynamicform/dynamicEntityCreateModal";

let refreshkey = 0;
let createkey = 0;
let detailKey = 0;

class CallsListDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callData: [],
      open: false,
      callId: "",
      detailId: "",
      isActive: true,
      edit: false,
      master_key: "communication-call",
      disableConfirm: false,
      formSearch: {
        entity: props.data.entity_name ?? "",
        entity_id: props.data.entity_id ?? "",
        page: 1,
        per_page: 15,
      },
      lastPage: 1,
      Sroll_Top: 1,
      onload: false,
      currentPage: 1,
      confirmData: {},
      openAction: false,
      snackData: { open: false, message: "", variant: "" },
      callListFieldMapping: getProjectItem("callListFieldMapping") ?? {},
      callDetailsData: {},
      showLoader: false,
    };
    this.listInnerRef = React.createRef();

  }
  componentWillReceiveProps = (props) => {
    this.setState({
      formSearch: {
        ...this.state.formSearch,
        entity: props.data.entity_name ?? "",
        entity_id: props.data.entity_id ?? "",
      },
    });
  };
  componentDidMount = () => {
    if (this.props.opencalls) this.getCall(true);
  };

  getMappedValue = (key, item) => {
    return getMappedValue("callListFieldMapping", key, item);
    // try {
    //   const keys = this.state.callListFieldMapping[key].split(".")
    //   var value = item;
    //   keys.forEach((splitKey) => {
    //     value = value[splitKey]
    //   })
    //   return value;
    // }
    // catch {
    //   return "--";
    // }

  }

  getCall = () => {
    this.setState({ showLoader: true });
    getCalls(this.state.formSearch)
      .then((res) => {
        this.setState(
          {
            callData: res.data?.data,
            isActive: false,
            lastPage: res?.data?.last_page,
            currentPage: res?.data?.current_page,
            showLoader: false,
          },
          () => { }
        );
      })
      .catch((err) => {
        this.setState({ isActive: false, showLoader: false, });
      });
  };
  getpagedCall = () => {
    const { callData } = this.state;
    getCalls(this.state.formSearch)
      .then((res) => {
        let glbData = [...res.data?.data];
        glbData?.forEach((item) => {
          callData?.forEach((elm, ind) => {
            if (item.id === elm.id) {
              delete callData[ind];
            }
          });
          callData.push(item);
        });

        this.setState(
          {
            callData,
            isActive: false,
            lastPage: res?.data?.last_page,
            currentPage: res?.data?.current_page,
            onload: false,
          },
          () => {
            if (this.state.Sroll_Top > 1) {
              this.listInnerRef.current.scrollTop = this.state.Sroll_Top + 50;
            }
          }
        );
      })
      .catch((err) => {
        this.setState({ isActive: false });
      });
  };
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };
  handleConfirm = () => {
    this.setState({
      disableConfirm: true,
    });
    this.handleDelete();
  };
  handleDeleteOpen = (e, item) => {
    this.setState({
      detailId: item.id,
      openAction: true,
      confirmData: {
        title: getMessageForKey("delete_call_header") ?? "",
        id: 1,
        message: getMessageForKey("delete_call_msg") ?? "",
        secondary_action: "Cancel",
        primary_action: "Remove",
      },
    });
  };

  getDetails = (callId) => {
    getCallDetails(callId)
      .then((res) => res.data)
      .then((res) => {
        //
        console.log("call res", res);
        this.setState({
          callDetailsData: res,
          // snackData: {
          //   open: true,
          //   message: res.message,
          //   variant: "success",
          // },
        });
      })
      .catch((error) => {
        var errorString = FormatError(error);

        this.setState({
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };

  // handleManageActions = (event, rowData) => {
  //   this.setState({
  //     callId: rowData.id,
  //     actionButton: true,
  //     anchorEl: event.currentTarget,
  //   });
  // };
  handleActionClose = () => {
    this.setState({ openAction: false });
  };

  handleDelete = () => {
    //
    deleteCall(this.state.detailId)
      .then((res) => res)
      .then((res) => {
        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: res.message,
            variant: "success",
          },
        });
        this.getCall();
        this.handleActionClose();
      })
      .catch((error) => {
        var errorString = FormatError(error);
        //

        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };
  handleCallDetails = (item) => {
    this.setState({ opencallsdetails: true, callId: item.id }, () => {
      detailKey = detailKey + 1;
    });
  };
  filterClose = () => {
    this.setState({ opencallsdetails: false });
    this.getCall();
  };

  handleAddCall = (data) => {
    if (data) {
      // data = {
      //   ...data,
      //   entity: this.props.data.entity_name ?? "",
      //   entity_id: this.props.data.entity_id ?? "",
      // }
      data.append("entity", this.props.data.entity_name ?? "");
      data.append("entity_id", this.props.data.entity_id ?? "");
      if (this.state.edit) {
        updateCall(this.state.detailId, data)
          .then((res) => res)
          .then((res) => {
            this.setState({
              snackData: {
                open: true,
                message: (res && res?.message) ? res.message : "Call updated successfully",
                variant: "success",
              },
            });
            this.getCall();
            this.handleClose();
          })
          .catch((error) => {
            var errorString = FormatError(error);

            this.setState({
              snackData: {
                open: true,
                message: errorString,
                variant: "error",
              },
            });
          });
      } else {
        createCall(data)
          .then((res) => res)
          .then((res) => {
            this.setState({
              snackData: {
                open: true,
                message: res.message,
                variant: "success",
              },
            });

            this.handleClose();

            this.getCall();
          })
          .catch((error) => {
            var errorString = FormatError(error);

            this.setState({
              snackData: {
                open: true,
                message: errorString,
                variant: "error",
              },
            });
          });
      }
      // this.getCall();
    }
    else {
      this.setState({
        disableConfirm: false,
        snackData: {
          open: true,
          message: "Could not add call",
          variant: "error",
        },
      });
    }
  }

  handleClickOpen = () => {
    console.log()
    this.setState(
      {
        edit: false,
        open: true,
      },
      () => {
        createkey = createkey + 1;
      }
    );
  };

  handleEdit = (e, item) => {
    this.setState({ detailId: item.id, open: true, edit: true }, () => {
      createkey = createkey + 1;
    });
  };

  handleClose = (id) => {
    if (id && !this.state.edit) {
      this.setState({ formSearch: { ...this.state.formSearch, page: 1 } });
      this.getCall();
    } else if (id && this.state.edit) {
      this.getpagedCall();
    }
    this.setState({ open: false });
  };
  truncateDesc = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  formatDate = (date) => {
    const formattedDate = date
      ? moment.utc(date).format("DD-MM-YYYY | hh:mm A")
      : "--";
    return formattedDate;
  };
  handleSearch = (page, scrolTop) => {
    this.setState(
      {
        onload: true,
        formSearch: {
          ...this.state.formSearch,
          page: page,
          // per_page: this.state.formSearch.per_page + 10,
        },
        Sroll_Top: scrolTop,
      },
      () => {
        this.getpagedCall();
      }
    );
  };
  handleScroll = (e) => {
    if (this.listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        this.listInnerRef.current;

      const { currentPage, lastPage } = this.state;
      this.listInnerRef.current.scrollTop = scrollTop;
      if (!this.state.onload) {
        if (lastPage > currentPage) {
          if (Math.trunc(scrollTop) + clientHeight === scrollHeight) {
            setTimeout(() => {
              this.handleSearch(currentPage + 1, scrollHeight);
            }, 1000);
          }
        }
      }
    }
  };

  getCallDateForItem = (item) => {
    // const date_time = item[this.state.callListFieldMapping['call_date']];
    const date_time = this.getMappedValue('call_date', item); // item[this.state.callListFieldMapping['call_date']];
    const call_date = date_time
      ? moment.utc(date_time).format("DD-MM-YYYY")
      : "--";
    return call_date;
  }
  getCallTimeForItem = (item) => {
    // const date_time = item[this.state.callListFieldMapping['call_date']];
    const date_time = this.getMappedValue('call_date', item);
    const call_time = date_time
      ? moment.utc(date_time).format("hh:mm A")
      : "--";

    return call_time;
  }


  render() {
    const { classes } = this.props;
    const { callData, isActive, showLoader } = this.state;

    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.opencalls}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="note-drawer"
        >
          <div className="note-popup">
            <div className="note-head">
              <div className="dropdown">
                <a
                  className={`${classes.tabButton} link`}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  All Calls
                  {/* <CustomIcons iconName="expand" iconClass="icon" /> */}
                </a>

                {/* <div className="dropdown-menu">
                  <a className="dropdown-item" href="#">
                    All Call
                  </a>
                </div> */}
              </div>
              <div className="righ-container">
                {/* <SearchBoxSecondary />
                <Iconbutton
                  title="Filter"
                  iconName="filter"
                  iconClass="tableButtonIcons"
                  className=" icon-only"
                /> */}
                <div className="item ">
                  <button
                    onClick={this.handleClickOpen}
                    className={`${this.props.classes.primaryBtn} btn btn-primary  btnsml`}
                  >
                    Create
                  </button>
                </div>

              </div>
            </div>
            {isActive ? (
              <>
                <div className="note-list">
                  {[1, 2, 3].map((item) => (
                    <div className="item notes-item">
                      <div className="note_details">
                        <div className="skeltron-head">
                          <Skeleton variant="text" animation="wave" />
                        </div>
                        <div className="details">
                          <Skeleton variant="text" height={32} />
                        </div>
                      </div>
                      <div className="notes_date">
                        <span className="text-block">
                          <Skeleton variant="text" width={100} height={15} />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                {showLoader && (
                  <div className="page_overlay">
                    <BlockingLoader isActive={showLoader} />
                  </div>
                )}
                {callData && callData.length ? (
                  <>
                    <div
                      className="note-list notes-list activity-list"
                      ref={this.listInnerRef}
                      onScroll={(e) => this.handleScroll(e)}
                    >
                      {callData?.map((item) => (
                        <div className="item notes-item">
                          <div className="note_details">
                            <div className="tag-line tag-flex">
                              <span className={`${classes.tagactiveColor} tag`}>
                                {/* {item[callListFieldMapping['direction']]} */}
                                {this.getMappedValue('direction', item)}
                              </span>
                              <div className="dropdown">
                                <a
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <CustomIcons
                                    iconName="tabledropdown"
                                    iconClass="icon"
                                  />
                                </a>

                                <div className="dropdown-menu">
                                  <a
                                    className="dropdown-item"
                                    onClick={(e) => this.handleEdit(e, item)}
                                    href="#"
                                  >
                                    Edit
                                  </a>

                                  <a
                                    className="dropdown-item"
                                    onClick={(e) =>
                                      this.handleDeleteOpen(e, item)
                                    }
                                    href="#"
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="details">
                              <a
                                className={` link w-100`}
                                href="#"
                                onClick={(e) => this.handleCallDetails(item)}
                              >
                                <>
                                  {/* {item.name ?? ""} */}
                                  {this.getMappedValue('from_name', item)
                                    ? this.truncateDesc(this.getMappedValue('from_name', item), 50)
                                    : "--"} {this.getMappedValue('from_number', item) ?? "--"}
                                  {/* {item[callListFieldMapping['from_name']]
                                    ? this.truncateDesc(item[callListFieldMapping['from_name']], 50)
                                    : "--"} {item[callListFieldMapping['from_number']] ?? "--"} */}
                                  {/* Arjun +3539976578988 */}
                                </>
                              </a>
                              <div className="flex_Rows">
                                <div className="flex_Child">
                                  <div className="icon">
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M12.6667 2.85156H3.33333C2.59695 2.85156 2 3.44852 2 4.1849V13.5182C2 14.2546 2.59695 14.8516 3.33333 14.8516H12.6667C13.403 14.8516 14 14.2546 14 13.5182V4.1849C14 3.44852 13.403 2.85156 12.6667 2.85156Z" stroke="#0077BE" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M10.667 1.51562V4.18229" stroke="#0077BE" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M5.33301 1.51562V4.18229" stroke="#0077BE" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M2 6.85156H14" stroke="#0077BE" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                  </div>
                                  <span>{this.getCallDateForItem(item)}</span>
                                </div>
                                {/* <div className="flex_Child">
                                  <div className="icon">
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M7.99968 14.849C11.6816 14.849 14.6663 11.8642 14.6663 8.18229C14.6663 4.50039 11.6816 1.51562 7.99968 1.51562C4.31778 1.51562 1.33301 4.50039 1.33301 8.18229C1.33301 11.8642 4.31778 14.849 7.99968 14.849Z" stroke="#0077BE" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M8 4.18359V8.18359L10.6667 9.51693" stroke="#0077BE" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                  </div>
                                  <span>{this.getCallTimeForItem(item)}</span>
                                </div> */}
                                <div className="flex_Child">
                                  <div className="icon">
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M7.99968 14.849C11.6816 14.849 14.6663 11.8642 14.6663 8.18229C14.6663 4.50039 11.6816 1.51562 7.99968 1.51562C4.31778 1.51562 1.33301 4.50039 1.33301 8.18229C1.33301 11.8642 4.31778 14.849 7.99968 14.849Z" stroke="#0077BE" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M8 4.18359V8.18359L10.6667 9.51693" stroke="#0077BE" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                  </div>
                                  <span>{this.getMappedValue('duration', item) ?? "--"}</span>
                                </div>
                              </div>
                              <div className="flex_Column">
                                <div className="flex_Child">
                                  <span className="sub">Call Status :</span>
                                  <span className="text">{this.getMappedValue('call_status', item) ?? "--"}</span>
                                </div>
                                {

                                }
                                {/* <div className="flex_Child">
                                  <span className="sub">Call Handled By :</span>
                                  <span className="text">{this.getMappedValue('call_agent', item) ?? "--"}</span>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          {/* <div className="calls_date">
                            <span className="text-block">
                              <span className="sub">Created by</span>
                              <span className="t-box">
                                {item.created_by
                                  ? (item?.created_by?.first_name ?? "") +
                                    " " +
                                    (item?.created_by?.last_name ?? "")
                                  : "--"}
                              </span>
                              <span className="t-box">
                                | {this.formatDate(item?.created_at)}
                              </span>
                            </span>
                          </div> */}
                        </div>
                      ))}

                      {/* <Grid
                        container
                        style={{
                          position: "relative",
                        }}
                      >
                        <Grid
                          align="center"
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <BlockingLoader
                            isActive={this.state.onload}
                          ></BlockingLoader>
                        </Grid>
                      </Grid> */}
                    </div>
                  </>
                ) : (
                  <div className="call-list note-list activity-list popup-stretch-block">
                    <div className="empty-content">
                      <div className="img-box">
                        <img src="/assets/images/illustrations/no-calls.png" />
                      </div>
                      <p className="text">There are no items to display.</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Drawer>
        <ActionDialog
          {...this.props}
          key={refreshkey}
          open={this.state.openAction}
          data={this.state.confirmData}
          handleClose={this.handleActionClose}
          handleConfirm={this.handleConfirm}
          disableConfirm={this.state.disableConfirm}
        />
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
        <CallDetailDrawer
          {...this.props}
          key={detailKey}
          master_key={this.state.master_key}
          maindata={this.props.data}
          detailId={this.state.callId}
          classesStyle={this.props.classesStyle}
          opencallsdetails={this.state.opencallsdetails}
          filterClose={this.filterClose}
        />
        {this.state.open && (
          <DynamicEntityModal
            {...this.props}
            key={createkey}
            edit={this.state.edit}
            submit={this.handleAddCall}
            detailId={this.state.detailId}
            master_key={this.state.master_key}
            open={this.state.open}
            handleClose={this.handleClose}
            getDetails={this.getDetails}
            title={"Call"}
            title={"Call Log"}
            detailsData={this.state.callDetailsData}
            maindata={this.props.data}
          />
        )}

      </div>
    );
  }
}

export default CallsListDrawer;
