import Drawer from "@mui/material/Drawer";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import React, { Component } from "react";
import { FormatError } from "../../../../utils/formValidate";
import { getMessageForKey } from "../../../../utils/messages";
import ActionDialog from "../../../actiondialogs/actiondialog.component";
import CustomIcons from "../../../commonIcons";
import SnackComp from "../../../snackbar/snack.component";
import DynamicEntityModal from "../../../dynamicform/dynamicEntityCreateModal";
// import Iconbutton from "../../../IconButton";
import { createCall, deleteCall, getCallDetails, updateCall } from "../call.apis";
import "../calls_style.css";
import { getMappedValue, getProjectItem } from "../../../../utils/config";

let refreshkey = 0;
let createkey = 1;

class CallDetailDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailId: "",
      open: false,
      master_key: props.master_key ?? "",
      disableConfirm: false,
      detailData: [],
      confirmData: {},
      isActive: true,
      openAction: false,
      snackData: { open: false, message: "", variant: "" },
      // callDetailFieldMapping: getProjectItem("callDetailFieldMapping") ?? {},
    };
  }

  getMappedValue = (key) => {
    return getMappedValue("callDetailsFieldMapping", key, this.state.detailData) ?? "--";
  }

  getDetails = () => {
    this.setState({ isActive: true });
    getCallDetails(this.props.detailId)
      .then((res) => {
        this.setState({ detailData: res.data, isActive: false });
      })
      .catch((err) => { });
  };
  componentDidMount = () => {
    if (this.props.opencallsdetails) this.getDetails();
  };
  handleEdit = (e, item) => {
    this.setState({ detailId: item.id, open: true, edit: true }, () => {
      createkey = createkey + 1;
    });
  };
  handleClose = (id) => {
    if (id) {
      this.getDetails();
    }
    this.setState({ open: false });
  };
  handleConfirm = () => {
    this.setState({
      disableConfirm: true,
    });
    this.handleDelete();
  };
  handleDeleteOpen = (e) => {
    this.setState({
      detailId: this.props.detailId,
      openAction: true,
      confirmData: {
        title: getMessageForKey("delete_call_header") ?? "",
        id: 1,
        message: getMessageForKey("delete_call_msg") ?? "",
        secondary_action: "Cancel",
        primary_action: "Remove",
      },
    });
  };
  handleDelete = () => {
    deleteCall(this.props.detailId)
      .then((res) => res)
      .then((res) => {
        this.setState({
          snackData: {
            open: true,
            message: res.message,
            variant: "success",
          },
        });
        this.handleActionClose();

        setTimeout(() => {
          this.props.filterClose();
        }, 1000);
      })
      .catch((error) => {
        var errorString = FormatError(error);

        this.setState({
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };
  handleActionClose = () => {
    this.setState({ openAction: false });
  };
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };
  handleAddCall = (data) => {
    if (data) {

      data.append("entity", this.props.data.entity_name ?? "");
      data.append("entity_id", this.props.data.entity_id ?? "");
      if (this.state.edit) {
        updateCall(this.state.detailId, data)
          .then((res) => res)
          .then((res) => {
            this.setState({
              snackData: {
                open: true,
                message: (res && res?.message) ? res.message : "Call updated successfully",
                variant: "success",
              },
            });
            this.getDetails();
            this.handleClose();
          })
          .catch((error) => {
            var errorString = FormatError(error);

            this.setState({
              snackData: {
                open: true,
                message: errorString,
                variant: "error",
              },
            });
          });
      } else {
        createCall(data)
          .then((res) => res)
          .then((res) => {
            this.setState({
              snackData: {
                open: true,
                message: res.message,
                variant: "success",
              },
            });

            this.handleClose();

            this.getDetails();
          })
          .catch((error) => {
            var errorString = FormatError(error);

            this.setState({
              snackData: {
                open: true,
                message: errorString,
                variant: "error",
              },
            });
          });
      }
      // this.getCall();
    }
    else {
      this.setState({
        disableConfirm: false,
        snackData: {
          open: true,
          message: "Could not add call",
          variant: "error",
        },
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { detailData, isActive } = this.state;

    const created_at_date = this.getMappedValue('date_time')
      ? moment.utc(this.getMappedValue('date_time')).format("DD-MM-YYYY")
      : "--";
    const call_date = this.getMappedValue('call_date')
      ? moment.utc(this.getMappedValue('call_date')).format("DD-MM-YYYY")
      : "--";

    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.opencallsdetails}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="note-drawer"
        // onOpen={toggleDrawer(anchor, true)}
        >
          {/* {list(anchor)} */}
          <div className="note-popup">
            <div className="note-head">
              <a
                className={`${classes.tabButton} link`}
                herf="#"
                onClick={(e) => this.props.filterClose()}
              >
                <span className="icon">
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.8125 12.625L1.1875 7L6.8125 1.375"
                      stroke="#0077BE"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Back
              </a>
              <div className="righ-container">
                {/* <SearchBoxSecondary /> */}
                {/* <Iconbutton
                  title="Filter"
                  iconName="filter"
                  iconClass="tableButtonIcons"
                  className=" icon-only"
                /> */}
                {/* <CreateModal {...this.props} /> */}
              </div>
            </div>
            <div className="note-list note-detail">
              {isActive ? (
                <div className="item">
                  <div className="details">
                    <div className="row-block">
                      <p className="text">
                        <Skeleton variant="text" width={70} />
                      </p>
                    </div>
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          <Skeleton variant="text" width={70} />
                        </span>
                        <span
                          className="text"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          <Skeleton variant="text" width={360} />
                        </span>
                      </div>
                    </div>
                    <div className="details details_margin">
                      <div className="row-block">
                        <span className="text-block">
                          <span className="sub">
                            <Skeleton variant="text" width={200} />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="item">
                  <div className="tag-line">
                    <span className={`${classes.tagactiveColor} tag`}>
                      {this.getMappedValue("call_status_id")}
                    </span>
                    <div className="dropdown">
                      <a
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <CustomIcons
                          iconName="tabledropdown"
                          iconClass="icon"
                        />
                      </a>

                      <div className="dropdown-menu">
                        <a
                          className="dropdown-item"
                          onClick={(e) => this.handleEdit(e, detailData)}
                          href="#"
                        >
                          Edit
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={(e) => this.handleDeleteOpen()}
                          href="#"
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="details">
                    <div className="row-block">
                      <p className="text">{this.getMappedValue('name')} </p>
                    </div>
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          From Number

                        </span>
                        <span className="text">{this.getMappedValue('from_number')}</span>
                      </div>
                      <div className="attribute">
                        <span className="sub">
                          To Number

                        </span>
                        <span className="text">{this.getMappedValue('to_number')}</span>
                      </div>
                    </div>
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          Call Direction

                        </span>
                        <span
                          className={`${classes.linkColor} text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.getMappedValue('call_direction')}
                        </span>
                      </div>
                      <div className="attribute">
                        <span className="sub">
                          Call Duration

                        </span>
                        <span
                          className={`${classes.linkColor} text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.getMappedValue('duration')}
                        </span>
                      </div>
                    </div>
                    <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                        Date

                        </span>
                        <span
                          className={`${classes.linkColor} text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {call_date}
                        </span>
                      </div>
                      {/* <div className="attribute">
                        <span className="sub">
                          Duration
                        </span>
                        <span
                          className={`${classes.linkColor} text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {}
                          15 Min 10 Sec
                        </span>
                      </div> */}
                    </div>
                    {/* <div className="row-block">
                      <div className="attribute">
                        <span className="sub">
                          Call Record
                          <span
                            className="icon-padding"
                            data-toggle="tooltip"
                            title=""
                          >
                            <CustomIcons
                              iconName="help"
                              iconClass="header-action-icons"
                            />
                          </span>
                        </span>
                        <span
                          className="text"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.getMappedValue("description") ?? "--"}
                        </span>
                      </div>
                    </div> */}
                    <div className="row-block">
                      {/* <div className="attribute">
                        <span className="sub">
                          Call Answered By
                        
                        </span>
                        <span
                          className={`${classes.linkColor} text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.getMappedValue('answered_by')}
                        </span>
                      </div> */}
                      <div className="attribute">
                        <span className="sub">
                          Call Handled By

                        </span>
                        <span
                          className={`${classes.linkColor} text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.getMappedValue('handled_by')}
                        </span>
                      </div>
                    </div>
                    <div className="row-block">
                      {/* <div className="attribute">
                        <span className="sub">
                          Call Outcome

                        </span>
                        <span
                          className={`${classes.linkColor} text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.getMappedValue('callout_come')}
                        </span>
                      </div> */}
                      <div className="attribute">
                        <span className="sub">
                          Description

                        </span>
                        <span
                          className={` text`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.getMappedValue('call_outcome_remarks')}
                        </span>
                      </div>
                    </div>

                  </div>
                  <div className="details details_margin">
                    <div className="row-block">
                      <span className="text-block">
                        <span className="sub">Created by</span>
                        <span className="t-box">

                          {this.getMappedValue('created_by')}
                          {/* {detailData[callDetailFieldMapping['created_by']]} */}
                        </span>
                        <span className="t-box">{created_at_date}</span>
                        {/* <span className="t-box">{created_at_time}</span> */}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Drawer>

        <ActionDialog
          {...this.props}
          key={refreshkey}
          open={this.state.openAction}
          data={this.state.confirmData}
          handleClose={this.handleActionClose}
          handleConfirm={this.handleConfirm}
          disableConfirm={this.state.disableConfirm}
        />
        {this.state.open && (

          < DynamicEntityModal
            {...this.props}
            key={createkey}
            edit={true}
            detailId={this.state.detailId}
            master_key={this.state.master_key}
            open={this.state.open}
            handleClose={this.handleClose}
            maindata={this.props.maindata}
            getDetails={this.getDetails}
            title={"Call"}
            detailsData={this.state.detailData}
            submit={this.handleAddCall}
          />
        )}
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
      </div>
    );
  }
}

export default CallDetailDrawer;
