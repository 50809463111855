// import IconButton from "@material-ui/core/IconButton";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import MenuItem from "@material-ui/core/MenuItem";
// import CloseIcon from "@material-ui/icons/Close";
// import CircularProgress from "@material-ui/core/CircularProgress";

// import TextField from "../textfield";
import { debounce } from "@material-ui/core";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React from "react";
import "./style.css";

// let loading = false;
class SearchSelectBox extends React.Component {
  constructor(props) {
    super();
    this.state = {
      toggleHeader: "",
      value: props.value ?? "",
      loading: false,
      open: false,
    };
  }
  componentDidMount = () => {
    if (
      this.props.value !== "" &&
      this.props.value !== undefined &&
      this.props.value !== null
    )
      this.setState({
        value: this.props.value,
        loading: this.props.Data && !this.state.open ? false : true,
      });
    else {
      this.setState({ loading: false });
    }
  };

  componentWillReceiveProps = (props) => {
    this.setState({ value: props.value });
  };

  handlemenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleChange = (e, newValue) => {
    if (newValue) {
      this.props.onChange(newValue);
    } else {
      let data = { id: null, name: "", Param1: false };
      this.props.onChange(data);
    }
  };

  getUpdatedData = debounce((newValue) => {
    this.setState({ loading: false });
    this.props.getData(newValue);
  }, 1000);

  handleInputChange = (e, newValu, reason) => {
    if (reason === "input") {
      this.setState(
        {
          value: newValu,
          loading: true,
        },
        () => {
          if (newValu === "" || newValu === undefined || newValu === null) {
            let data = { id: null, name: "", Param1: false };
            this.props.onChange(data);
          } else {
            let data = { id: null, name: newValu, Param1: false };
            this.props.onChange(data);
          }
          if (this.props.getData && newValu) {
            this.getUpdatedData(newValu);
          }
        }
      );
    } else if (reason === "reset") {
      // this.setState({
      //   open: false
      // })
    }
  };

  handleOpen = () => {
    if (this.props.async) {
      let row = this.props.row ? this.props.row : {};
      this.setState(
        {
          // loading: true,
          open: true,
        },
        () => {
          // if (this.props.getData && this.state.value) {
          //   this.props.getData(this.state.value);
          //   setTimeout(() => {
          //     this.setState({ loading: false });
          //   }, 2000);
          // }
        }
      );
    } else {
      if (this.props.getData) {
        this.setState(
          {
            loading: this.props.Data.length === 0 ? true : false,
            open: true,
          },
          () => {
            // if (this.props.Data.length === 0 && this.props.getData) {
            this.props.getData();
            setTimeout(() => {
              this.setState({ loading: false });
            }, 2000);
            // }
          }
        );
      } else {
        this.setState({ open: true });
      }
    }
  };

  _handleKeyDown = (e) => {
    if (this.props.onKeyDownEvent) {
      if (e.key === "Enter") {
        this.props.onKeyDownEvent(e);
        this.setState({
          open: false,
        });
      }
    }
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const {
      Data,
      label,
      margin,
      row,
      freewidth,

      name,
      InputProps,
      noloader,
      defaultValue,
      required,
      InputLabelProps,
      readOnly,
      error,
      classes,
    } = this.props;

    const { value, open, loading } = this.state;
    // loading = open && Data.length === 0 ? true : false;

    return (
      <>
        {readOnly ? (
          <TextField
            {...this.props}
            id="select-pagesize"
            type="text"
            label={label ?? null}
            fullWidth={freewidth ? false : true}
            name={name}
            value={value}
            margin={margin}
            // variant="outlined"
            InputLabelProps={InputLabelProps}
            required={required}
            disabled={true}
            InputProps={{
              readOnly: true,
            }}
          />
        ) : (
          <Autocomplete
            {...this.props}
            onKeyDown={this._handleKeyDown}
            className="custom-autocomplete"
            options={Data}
            getOptionLabel={(option) =>
              this.props.key_name ? option[this.props.key_name] : option.name
            }
            onChange={(e, newValue) => this.handleChange(e, newValue)}
            inputValue={value ? value : ""}
            // variant="outlined"
            open={open}
            fullWidth={freewidth ? false : true}
            onOpen={this.handleOpen}
            ListboxProps={{ style: { maxHeight: "15rem" } }}
            onClose={this.handleClose}
            defaultValue={defaultValue}
            loading={loading}
            noOptionsText={"No Results found"}
            onInputChange={(event, newInputValue, reason) => {
              this.handleInputChange(event, newInputValue, reason);
            }}
            disableClearable={
              value === "" || value === undefined || value === null
            }
            renderInput={(params) => (
              <TextField
                {...this.props}
                {...params}
                required={required}
                value={value ? value : ""}
                name={name}
                // variant="outlined"
                label={label ?? null}
                defaultValue={defaultValue}
                // placeholder={label}
                margin="dense"
                InputLabelProps={{ shrink: true, ...InputLabelProps }}
                error={error}
                fullWidth={freewidth ? false : true}
                InputProps={{
                  ...params.InputProps,
                  ...InputProps,
                  readOnly: readOnly,

                  endAdornment: (
                    <React.Fragment>
                      {!noloader && loading ? (
                        <CircularProgress
                          //   color={commonStyle.buttonColor}
                          size={20}
                        />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        )}
      </>
    );
  }
}

export default SearchSelectBox;
