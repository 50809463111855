import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";

const GuestRoute = ({
  isAuthenticated,
  component: Component,
  classes,
  // layout: Layout,
  ...rest
}) => {
  let path = sessionStorage.getItem("nav_path") ?? "/user-dashboard";
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? (
          <>
            {Component ? (
              <Component {...props} {...rest} classes={classes} />
            ) : (
              <Redirect to="/" />
            )}
          </>
        ) : (
          <Redirect to={path} />
        )
      }
    />
  );
};

GuestRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default GuestRoute;
