import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonSecondary from "../../buttonsecondary";
import DynTableComp from "../../dyntable/dyntable.component";
import StatiForm from "../../../common_components/staticform/static_form_builder";
import DynamicFormBuilder from "../../../common_components/dynamicform/dynamic_form_builder";
import { FormatError } from "../../../utils/formValidate";
import { submitDyndata, getdynForm, submitCreateAssignDyndata } from "../../../page_container/core_components/api.functions";

class TableSubComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      showTable: true,
      pathname: "",

      header: {},
      tablehkey: 1,
      dynCreateData: [],
      createData: [],
      initData: [],
      open: false,
      isActive: false,
      showLoader: true,
      relation: {},
      overview: [],
      addData: [],
      statgesData: [],
      snackData: { open: false },
      master_key: "",
      canSubmit: true,
      submoduleDataUrl: "",
      child_key: "",
      excludeKeys: null,
    };
    this.subchild = React.createRef();
  }

  componentDidMount = () => {
    this.handlePath();
  }
  handleManageEdit = () => {
    this.setState({ editable: true });
  };
  handleManageSave = () => {
    this.setState({ editable: false });
  };
  handleData = (data) => {
    //

    if (data && data.length) {
      this.setState({ showTable: true });
    } else {
      this.setState({ showTable: false });
    }
  };
  handlePath = () => {

    if ((localStorage.getItem("profile_popup_name")) === 'Admin Portal') {
      this.setState({ pathname: `/admin/submodule/details` });
    }
    else {
      this.setState({ pathname: `/submodule/details` });

    }
  };
  handlePaginatedData = (data) => { };

  getdyndata = (key, item, excludeKeys) => {
    this.setState({ child_key: key })

    getdynForm(key)
      .then((res) => {
        if (res.data) {
          let data = res.data.sort(function (a, b) {
            return a.sort_order - b.sort_order;
          });
          this.setState(
            {
              // open: true,
              edit: false,
              dynCreateData: data,
              excludeKeys: excludeKeys,
              isActive: false,
              showLoader: false,
              submoduleDataUrl: `/attribute/keys/${key}?quick_create=true`,
              open: true,
              relation: item,
              type: "create-add",
            },
            () => { }
          );
        }
      })
      .catch((error) => {
        var errorString = FormatError(error);
        this.setState({
          isActive: false,
          showLoader: false,
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  }

  handleCreate = (action, item) => {
    // this.setState({ showLoader: true });
    if (action.type === 'create-add') {
      const specialChars = /[|]/;
      var excludeKeys = null;
      if (specialChars.test(action.api_url) === true) {
        const api_url = action.api_url.split('|');
        action.api_url = api_url[0];
        excludeKeys = api_url.length > 1 ? api_url.slice(1, api_url.length) : null;
      }
      this.getdyndata(action.api_url, item, excludeKeys)
    } else {
      this.setState({
        // showLoader: true,
        submoduleDataUrl: action?.api_url ?? "",
        open: true,
        relation: item,
        type: "add",
      });
    }
  };

  handleDynSubmit = (data) => {
    if (this.state.type === 'add') {
      let form = {
        parent_entity: this.props.master_key,
        related_entity: this.state.child_key, //this.state.relation.relation_model,
        parent_id: this.props.list_id,
        related_ids: data,
        relation_name: this.state.relation.relation_name,
      };
      submitDyndata(form)
        .then((res) => res)
        .then((res) => {
          this.setState({
            snackData: {
              open: true,
              message: res.message,
              variant: "success",
            },
            tablehkey: this.state.tablehkey + 1,
          });

          this.props.getdetail(this.props.list_id);
          this.handleClose();
        })
        .catch((error) => {
          var errorString = FormatError(error);
          //

          this.setState({
            snackData: {
              open: true,
              message: errorString,
              variant: "error",
            },
          });
        });
    } else {

      const fields = data;
      if (this.state.excludeKeys && this.state.excludeKeys.length > 0) {
        fields[this.state.excludeKeys[0]] = this.props.list_id
      }
      let form = {
        parent_model: this.props.master_key,
        parent_id: this.props.list_id,
        relation_name: this.state.relation.relation_name,
        fields: fields,
      };

      submitCreateAssignDyndata(form)
        .then((res) => res)
        .then((res) => {
          this.setState({
            snackData: {
              open: true,
              message: res.message,
              variant: "success",
            },
            tablehkey: this.state.tablehkey + 1,
          });

          this.props.getdetail(this.props.list_id);
          this.props.handleClose();
        })
        .catch((error) => {
          var errorString = FormatError(error);
          //

          this.setState({
            snackData: {
              open: true,
              message: errorString,
              variant: "error",
            },
          });
        });

    };
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { classes, tabData } = this.props;
    const { editable, showTable, pathname } = this.state;

    return (
      <>
        <div>
          <h2 class="accordion-header twocolumnblock">
            <button class="accordion-button disabled">{tabData.name}</button>
            <div className="right-container">
              {this.subchild.current?.handlePageData() && (
                <Link
                  to={{
                    pathname: pathname,
                    state: {
                      master_key: this.props.master_key,
                      detail_Id: this.props.list_id,
                      ...tabData,
                    },
                  }}
                  className={`${classes.secondaryBtn} btn btn-secondary  btnsml`}
                >
                  View All
                </Link>
              )}
              {tabData.actions &&
                tabData.actions.section_action &&
                tabData.actions.section_action.map((act) => (
                  <ButtonSecondary
                    label={act.label}
                    iconData={{
                      name: act.type,
                      className: "primary-button-icon",
                    }}
                    className={`${classes.primaryBtn} btn btn-primary textButton btnsml`}
                    onClick={(e) => this.handleCreate(act, tabData)}
                  />
                )
                )}
            </div>
          </h2>
          {showTable ? (
            <div class="accordion-collapse table-block collapse show ">
              <div class="accordion-body">
                <div className="row">
                  <DynTableComp
                    {...this.props}
                    tableData={tabData}
                    ref={this.subchild}
                    key={tabData.id}
                    handleReload={this.props.handleReload}
                    master_key={this.props.master_key}
                    handleData={(e) => this.handleData(e)}
                  />
                </div>
              </div>
              {/* <div class="seemore"><a href="#" className={`${classes.linkColor} link`}>See more</a></div> */}
            </div>
          ) : (
            <div class="accordion-collapse table-block collapse">
              <div class="accordion-body"></div>
              {/* <div class="seemore"><a href="#" className={`${classes.linkColor} link`}>See more</a></div> */}
            </div>
          )}
        </div>

        {this.state.type === 'add' ? (
          <StatiForm
            {...this.props}
            addData={this.state.addData}
            open={this.state.open}
            formData={this.state.dynCreateData}
            submoduleDataUrl={this.state.submoduleDataUrl}
            master_key={this.state.child_key}
            submit={this.handleDynSubmit}
            handleClose={this.handleClose}
            title={this.state.relation.name}
          />
        ) : (
          <DynamicFormBuilder
            {...this.props}
            open={this.state.open}
            formData={this.state.dynCreateData}
            master_key={this.state.child_key}
            submit={this.handleDynSubmit}
            handleClose={this.handleClose}
            excludeKeys={this.state.excludeKeys}
            title={`Create and Assign ${this.state.relation.name}`}
          />

        )}
      </>
    );
  }
}

export default TableSubComponent;
