import React, { Component } from "react";
import { DynFormValidate } from "../../../utils/formValidate";
import DynamicForm from "../../dynamicform/dynamicform";
import SnackComp from "../../snackbar/snack.component";

class InformationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackData: { open: false, variant: "" },
    };
  }

  handleSubmitAction = () => {
    const { informData } = this.props;
    let flag = false;
    let data = [];
    var error = "";
    Object.keys(informData.data).forEach((key) => {
      data = DynFormValidate(informData.data[key]);

      data.forEach((item) => {
        if (item.error) {
          if (item.error) {
            flag = true;
            //
            if (error == "") {
              error = item.error_message
                ? item.error_message
                : "Please fill all required fields";
            } else {
              error =
                error + ", " + item.error_message
                  ? item.error_message
                  : "Please fill all required fields";
            }
          }
        }
      });
    });
    if (!flag) {
      let datas = {};
      Object.keys(informData.data).forEach((key) => {
        informData.data[key].forEach((item) => {
          datas = {
            ...datas,
            [item.api_name]: item.api_id ? item.api_id : item.api_value,
          };
        });
      });
      setTimeout(() => {
        this.props.handleSubmit(datas);
      }, 250);
    } else {
      this.setState(
        {
          submit: false,
          snackData: {
            open: true,

            variant: "error",
            message: error ? error : "Please fill all required fields",
          },
        },
        () => {
          this.props.handleSubmit();
        }
      );
    }
  };
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };
  render() {
    const { classes, informData, editable } = this.props;
    const master_id = this.props.match.params.id ?? "";
    return (
      <>
        {informData.data &&
          Object.keys(informData.data).map((key) => (
            <div class="accordion-collapse collapse show">
              <div class="accordion-body">
                <div className="row border-one">
                  <div className="row-title">
                    <h4>{key}</h4>
                  </div>
                  {informData.data &&
                    informData.data[key].map((item, index) => (
                      <DynamicForm
                        {...this.props}
                        key={item.id}
                        editable={!editable}
                        item={item}
                        master_id={master_id}
                        master_key={this.props.master_key}
                        index={index}
                        formData={informData.data[key]}
                      />
                    ))}
                </div>
              </div>
            </div>
          ))}
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
      </>
    );
  }
}

export default InformationComponent;
