import { Grid } from "@mui/material";
import Drawer from "@mui/material/Drawer";
// import Iconbutton from "../../IconButton";
// import SearchBoxSecondary from "../../secondarysearchBox";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import React, { Component } from "react";
import { FormatError } from "../../../../utils/formValidate";
import { getMessageForKey } from "../../../../utils/messages";
import ActionDialog from "../../../actiondialogs/actiondialog.component";
import CustomIcons from "../../../commonIcons";
import BlockingLoader from "../../../loader/blockingloader";
import SnackComp from "../../../snackbar/snack.component";
import NoteDetailDrawer from "../components/noteDetail";
import NoteModal from "../components/noteModal";
import { delete_Note, get_Note } from "../note.apis";
import "../note_style.css";

let refreshkey = 0;
let createkey = 0;
let detailKey = 0;

class NoteListDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noteData: [],
      open: false,
      noteId: "",
      detailId: "",
      isActive: true,
      edit: false,
      disableConfirm: false,
      formSearch: {
        entity: props.data.entity_name ?? "",
        entity_id: props.data.entity_id ?? "",
        page: 1,
        per_page: 15,
      },
      lastPage: 1,
      Sroll_Top: 1,
      onload: false,
      currentPage: 1,
      confirmData: {},
      openAction: false,
      snackData: { open: false, message: "", variant: "" },
      showLoader: false,
    };
    this.listInnerRef = React.createRef();
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      formSearch: {
        ...this.state.formSearch,
        entity: props.data.entity_name ?? "",
        entity_id: props.data.entity_id ?? "",
      },
    });
  };
  componentDidMount = () => {
    if (this.props.opennote) this.getNote(true);
  };
  getNote = () => {
    this.setState({ showLoader: true });
    get_Note(this.state.formSearch)
      .then((res) => {
        //
        this.setState(
          {
            noteData: res.data?.data,
            isActive: false,
            lastPage: res?.data?.last_page,
            currentPage: res?.data?.current_page,
            showLoader: false,
          },
          () => {}
        );
      })
      .catch((err) => {
        this.setState({ isActive: false, showLoader: false, });
      });
  };
  getpagedNote = () => {
    this.setState({ showLoader: true });
    const { noteData } = this.state;
    get_Note(this.state.formSearch)
      .then((res) => {
        let glbData = [...res.data?.data];
        glbData?.forEach((item) => {
          noteData?.forEach((elm, ind) => {
            if (item.id === elm.id) {
              delete noteData[ind];
            }
          });
          noteData.push(item);
        });

        this.setState(
          {
            showLoader: false,
            noteData,
            isActive: false,
            lastPage: res?.data?.last_page,
            currentPage: res?.data?.current_page,
            onload: false,
          },
          () => {
            if (this.state.Sroll_Top > 1) {
              this.listInnerRef.current.scrollTop = this.state.Sroll_Top + 50;
            }
          }
        );
      })
      .catch((err) => {
        this.setState({ isActive: false });
      });
  };
  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };
  handleConfirm = () => {
    this.setState({
      disableConfirm: true,
    });
    this.handleDelete();
  };
  handleDeleteOpen = (e, item) => {
    this.setState({
      detailId: item.id,
      openAction: true,
      confirmData: {
        title: getMessageForKey("delete_note_header") ?? "",
        id: 1,
        message: getMessageForKey("delete_note_msg") ?? "",
        secondary_action: "Cancel",
        primary_action: "Remove",
      },
    });
  };
  // handleManageActions = (event, rowData) => {
  //   this.setState({
  //     noteId: rowData.id,
  //     actionButton: true,
  //     anchorEl: event.currentTarget,
  //   });
  // };
  handleActionClose = () => {
    this.setState({ openAction: false });
  };

  handleDelete = () => {
    delete_Note(this.state.detailId)
      .then((res) => res)
      .then((res) => {
        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: res.message,
            variant: "success",
          },
        });
        this.getNote();
        this.handleActionClose();
      })
      .catch((error) => {
        var errorString = FormatError(error);
        //

        this.setState({
          disableConfirm: false,
          snackData: {
            open: true,
            message: errorString,
            variant: "error",
          },
        });
      });
  };
  handleNoteDetails = (item) => {
    this.setState({ opennotedetails: true, noteId: item.id }, () => {
      detailKey = detailKey + 1;
    });
  };
  filterClose = () => {
    this.setState({ opennotedetails: false });
    this.getNote();
  };
  handleClickOpen = () => {
    this.setState(
      {
        edit: false,
        open: true,
      },
      () => {
        createkey = createkey + 1;
      }
    );
  };
  handleEdit = (e, item) => {
    this.setState({ detailId: item.id, open: true, edit: true }, () => {
      createkey = createkey + 1;
    });
  };
  handleClose = (id) => {
    if (id && !this.state.edit) {
      this.setState({ formSearch: { ...this.state.formSearch, page: 1 } });
      this.getNote();
    } else if (id && this.state.edit) {
      this.getpagedNote();
    }
    this.setState({ open: false });
  };
  truncateDesc = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  formatDate = (date) => {
    const formattedDate = date
      ? moment.utc(date).format("DD-MM-YYYY | hh:mm A")
      : "--";
    return formattedDate;
  };
  handleSearch = (page, scrolTop) => {
    this.setState(
      {
        onload: true,
        formSearch: {
          ...this.state.formSearch,
          page: page,
          // per_page: this.state.formSearch.per_page + 10,
        },
        Sroll_Top: scrolTop,
      },
      () => {
        this.getpagedNote();
      }
    );
  };
  handleScroll = (e) => {
    if (this.listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        this.listInnerRef.current;

      const { currentPage, lastPage } = this.state;
      this.listInnerRef.current.scrollTop = scrollTop;
      if (!this.state.onload) {
        if (lastPage > currentPage) {
          if (Math.trunc(scrollTop) + clientHeight === scrollHeight) {
            setTimeout(() => {
              this.handleSearch(currentPage + 1, scrollHeight);
            }, 1000);
          }
        }
      }
    }
  };
  render() {
    const { classes } = this.props;
    const { noteData, isActive, showLoader } = this.state;
    console.log("lshowLoader", showLoader);

    return (
      <div>
        <Drawer
          anchor="right"
          open={this.props.opennote}
          onClose={this.props.filterClose}
          PaperProps={{
            style: {
              width: "580px",
              zIndex: 1000,
              right: "60px",
              // height:600,
              // padding:16
            },
          }}
          ModalProps={{
            keepMounted: false,
          }}
          BackdropProps={{ invisible: true }}
          className="note-drawer"
        >
          <div className="note-popup">
            <div className="note-head">
              <div className="dropdown">
                <a
                  className={`${classes.tabButton} link`}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  All Notes
                  {/* <CustomIcons iconName="expand" iconClass="icon" /> */}
                </a>

                {/* <div className="dropdown-menu">
                  <a className="dropdown-item" href="#">
                    All Note
                  </a>
                </div> */}
              </div>
              <div className="righ-container">
                {/* <SearchBoxSecondary />
                <Iconbutton
                  title="Filter"
                  iconName="filter"
                  iconClass="tableButtonIcons"
                  className=" icon-only"
                /> */}
                <div className="item ">
                  <button
                    onClick={this.handleClickOpen}
                    className={`${this.props.classes.primaryBtn} btn btn-primary  btnsml`}
                  >
                    Create
                  </button>
                </div>
                <NoteModal
                  key={createkey}
                  edit={this.state.edit}
                  detailId={this.state.detailId}
                  open={this.state.open}
                  handleClose={this.handleClose}
                  {...this.props}
                  maindata={this.props.data}
                />
              </div>
            </div>
            {isActive ? (
              <>
                <div className="note-list">
                  {[1, 2, 3].map((item) => (
                    <div className="item notes-item">
                      <div className="note_details">
                        <div className="skeltron-head">
                          <Skeleton variant="text" animation="wave" />
                        </div>
                        <div className="details">
                          <Skeleton variant="text" height={32} />
                        </div>
                      </div>
                      <div className="notes_date">
                        <span className="text-block">
                          <Skeleton variant="text" width={100} height={15} />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
               {showLoader && (
                    <div className="page_overlay">
                      <BlockingLoader isActive={showLoader} />
                    </div>
                  )}
                {noteData.length ? (
                  <>
                    <div
                      className="note-list activity-list"
                      ref={this.listInnerRef}
                      onScroll={(e) => this.handleScroll(e)}
                    >
                      {noteData?.map((item) => (
                        <div className="item notes-item">
                          <div className="note_details">
                            <div className="tag-line tag-flex">
                              <a
                                className={`${classes.tabButton} link`}
                                href="#"
                                onClick={(e) => this.handleNoteDetails(item)}
                              >
                                <>
                                  {/* {item.title ?? ""} */}
                                  {item.title.length
                                    ? this.truncateDesc(item.title, 50)
                                    : ""}
                                </>
                              </a>
                              <div className="dropdown">
                                <a
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <CustomIcons
                                    iconName="tabledropdown"
                                    iconClass="icon"
                                  />
                                </a>

                                <div className="dropdown-menu">
                                  <a
                                    className="dropdown-item"
                                    onClick={(e) => this.handleEdit(e, item)}
                                    href="#"
                                  >
                                    Edit
                                  </a>

                                  <a
                                    className="dropdown-item"
                                    onClick={(e) =>
                                      this.handleDeleteOpen(e, item)
                                    }
                                    href="#"
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="details">
                              <a
                                className="link "
                                href="#"
                                onClick={(e) => this.handleNoteDetails(item)}
                              >
                                {item.description.length
                                  ? this.truncateDesc(item.description, 120)
                                  : ""}
                              </a>
                              {/* <div className="attributes attribute-column">
                              <div className="attribute-list">
                                <span className="date">
                                  <span className="icon">
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12.6667 2.66797H3.33333C2.59695 2.66797 2 3.26492 2 4.0013V13.3346C2 14.071 2.59695 14.668 3.33333 14.668H12.6667C13.403 14.668 14 14.071 14 13.3346V4.0013C14 3.26492 13.403 2.66797 12.6667 2.66797Z"
                                        stroke="#0077BE"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M10.666 1.33203V3.9987"
                                        stroke="#0077BE"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M5.33398 1.33203V3.9987"
                                        stroke="#0077BE"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M2 6.66797H14"
                                        stroke="#0077BE"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </span>
                                  {this.formatDate(item?.created_at)}
                                </span>
                              </div>
                              <div className="attribute-list">
                                <span className="sub">Created By</span>
                                <span className="dot">:</span>
                                <span className="text">
                                  {item?.created_by.first_name + " " + item?.created_by.last_name ?? "--"}
                                </span>
                              </div>
                            </div> */}
                            </div>
                          </div>
                          <div className="notes_date">
                            <span className="text-block">
                              <span className="sub">Created by</span>
                              <span className="t-box">
                                {/* {item?.created_by.first_name +
                                  " " +
                                  item?.created_by.last_name ?? "--"} */}
                                {item.created_by
                                  ? (item?.created_by?.first_name ?? "") +
                                    " " +
                                    (item?.created_by?.last_name ?? "")
                                  : "--"}
                              </span>
                              <span className="t-box">
                                | {this.formatDate(item?.created_at)}
                              </span>
                            </span>
                          </div>
                        </div>
                      ))}

                      <Grid
                        container
                        style={{
                          position: "relative",
                        }}
                      >
                        <Grid
                          align="center"
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <BlockingLoader
                            isActive={this.state.onload}
                          ></BlockingLoader>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                ) : (
                  <div className="note-list popup-stretch-block">
                    <div className="empty-content">
                      <div className="img-box">
                        <img src="/assets/images/illustrations/no-notes.png" />
                      </div>
                      <p className="text">There are no items to display.</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Drawer>
        <ActionDialog
          {...this.props}
          key={refreshkey}
          open={this.state.openAction}
          data={this.state.confirmData}
          handleClose={this.handleActionClose}
          handleConfirm={this.handleConfirm}
          disableConfirm={this.state.disableConfirm}
        />
        <SnackComp
          snackData={this.state.snackData}
          handleClose={this.onSnackclose}
        />
        <NoteDetailDrawer
          {...this.props}
          key={detailKey}
          maindata={this.props.data}
          detailId={this.state.noteId}
          classesStyle={this.props.classesStyle}
          opennotedetails={this.state.opennotedetails}
          filterClose={this.filterClose}
        />
      </div>
    );
  }
}

export default NoteListDrawer;
