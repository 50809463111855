import { IconButton } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import {
  FormatError,
  FormValidate,
} from "../../../../common_components/../utils/formValidate";
import ButtonSml from "../../../../common_components/buttonsml";
import CustomIcons from "../../../../common_components/commonIcons";
import SnackComp from "../../../../common_components/snackbar/snack.component";
import TextareaField from "../../../../common_components/textareafield";
import TextField from "../../../../common_components/textfield";
import {
  create_Note,
  get_Note_Details,
  update_Note,
} from "../../notelist/note.apis";
import "../modal_style.css";

let required = ["title", "description"];
let error = false;
export default function ManageModal(props) {
  const [formData, setFormData] = useState([]);
  const [snackData, setSnackData] = useState({ open: false });
  const [submit, setSubmit] = useState(true);

  const getDetails = () => {
    get_Note_Details(props.detailId)
      .then((res) => res.data)
      .then((res) => {
        //
        setFormData({
          ...formData,
          title: res.title,
          description: res.description,
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setSnackData({
      open: false,
      message: null,
      variant: null,
    });
    setFormData({
      ...formData,
      title: "",
      description: "",
      entity: props.maindata.entity_name,
      entity_id: props.maindata.entity_id,
    });
    if (props.open && props.edit) {
      getDetails();
    }
    //
  }, [props.open]);

  const handleClose = () => {
    setTimeout(function () {
      error = false;
      props.handleClose(true);
    }, 1000);
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    error = FormValidate(formData, required);
    console.log("error", error)

    if (Object.keys(error).length === 0) {
      setSubmit(false);

      props.edit
        ? update_Note(props.detailId, formData)
            .then((res) => {
              setSnackData({
                open: true,
                message: res.message,
                variant: "success",
              });
              handleClose();
              // setSubmit(true);
              setTimeout(() => {
                setSubmit(true);
              }, 1000);
            })
            .catch((err) => {
              const errorMsg = FormatError(err);
              setSubmit(true);
              setSnackData({
                open: true,
                message: errorMsg,
                variant: "error",
              });
            })
        : create_Note(formData)
            .then((res) => {
              setSnackData({
                open: true,
                message: res.message,
                variant: "success",
              });
              handleClose();
              setTimeout(() => {
                setSubmit(true);
              }, 1000);
            })
            .catch((err) => {
              const errorMsg = FormatError(err);
              setSubmit(true);
              setSnackData({
                open: true,
                message: errorMsg,
                variant: "error",
              });
            });

    } else {
      setSubmit(true);
      setSnackData({
        open: true,
        variant: "error",
        message: "Please fill all required fields",
      });
    }
  };
  const onSnackclose = () => {
    setSnackData({ open: false });
  };
  //
  return (
    <>
      {/* <div className="item flex-btn"> */}
      {/* <button
          onClick={handleClickOpen}
          className={`${props.classes.primaryBtn} btn btn-primary  btnsml`}
        >
          Create
        </button>
      </div> */}
      <Dialog className="" open={props.open} onClose={handleClose}>
        <DialogTitle>
          {" "}
          <div className="row">
            <div className="col-12 p-0">
              <p className="m-0">
                {props.edit ? `Edit Notes` : `Create Notes`}
              </p>
            </div>
            <div className="col-2 pr-0">
              <IconButton className="closeButtonStyle" onClick={handleClose}>
                <CustomIcons iconName="close" />
              </IconButton>
            </div>
          </div>{" "}
        </DialogTitle>
        <form onSubmit={handleSubmit} noValidate>
          <DialogContent>
            <div className="activity-form">
              <TextField
                {...props}
                placeholder={`Title`}
                label={`Title`}
                // tooltip={`Title`}
                name="title"
                required
                onChange={handleChange}
                value={formData.title}
                error= {error.title}
//                 error={error}

              />
              <TextareaField
                {...props}
                placeholder="Description"
                onChange={handleChange}
                value={formData.description}
                label="Description"
                name="description"
                tooltip=""
                {...props}
                required

                error= {error.description}
//                 error={error}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonSml
              {...props}
              type="button"
              onClick={handleClose}
              className={`${props.classes.secondaryBtn} btn btn-secondary`}
              label="Cancel"
            />
            <ButtonSml
              {...props}
              disabled={!submit}
              type="submit"
              className={`${props.classes.primaryBtn} btn btn-primary`}
              label={props.edit ? "Update" : "Create"}
            />
          </DialogActions>
        </form>{" "}
        <SnackComp snackData={snackData} handleClose={onSnackclose} />
      </Dialog>
    </>
  );
}
