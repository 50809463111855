import { Dialog, IconButton } from "@material-ui/core";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Component, default as React } from "react";
import CustomIcons from "../../commonIcons";
import SnackComp from "../../snackbar/snack.component";
import { styled } from "@mui/material/styles";
import ButtonSml from "../../buttonsml";
import { DropzoneArea } from 'material-ui-dropzone';
import { AttachFile } from "@material-ui/icons";
import TextField from "../../textfield";
import BlockingLoader from "../../loader/blockingloader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
let temp = [];

let dropKey = 0;

export default class CreateAttachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      submit: false,
      selected_files: [],
      snackData: { open: false, message: "", variant: "" },

    }
  };
  componentWillReceiveProps = (props) => {
    this.setState({
      submit: false,
      selected_files: [],
    });
  };
  handleClose = () => {
    this.props.handleClose();
    this.setState({ submit: false });
  };

  addFiles = (files) => {

    const selected_files = this.state.selected_files.slice();

    if (files.length > 0) {
      files.forEach(item => {
        var filePath = item.name.split(".");
        const fileExtension = filePath[filePath.length - 1];
        const fileName = filePath.slice(0, filePath.length - 1).join(".");

        const file_data = {
          display_name: fileName,
          name: fileName,
          file: item,
          extension: fileExtension
        }
        selected_files.push(file_data)
      });
      this.setState({
        selected_files: selected_files,
      });
    }
    dropKey = dropKey + 1;
    // console.log("selected_files", this.state.selected_files)
  };


  handleFileData = (files) => {
    console.log("files", files)
    this.addFiles(files);
  };

  handleChange = (index, e) => {
    const selected_files = this.state.selected_files;
    const selected_file = selected_files[index]
    // .splice(index, 1);
    selected_file["display_name"] = e.target.value;
    selected_files[index] = selected_file;
    this.setState({
      selected_files: selected_files,
    });
  };


  handleRemoveAttachments = (e, item, indexi) => {

    e.preventDefault();
    // console.log("field", field);
    const temp = this.state.selected_files.slice().filter((item, index) => {
      return index !== indexi;
    });
    this.setState({
      selected_files: temp
    })

  };

  onSnackclose = () => {
    this.setState({ snackData: { open: false } });
  };

  handleSubmit = (e) => {

    e.preventDefault();
    let error = false;
    this.state.selected_files.forEach((item) => {
      if (item.display_name.trim() === '') {
        error = true
      }
    });
    if (error) {
      this.setState({
        disableConfirm: false,
        snackData: {
          open: true,
          message: "Please enter a valid filename",
          variant: "error",
        },
      });
    }
    else {
      this.setState({ submit: true });
      this.props.submit(this.state.selected_files);
    }

  };


  render() {
    const { display_name, showLoader, selected_files } = this.state;
    return (
      <>
        <BootstrapDialog
          className=" attachment_modal MuiModal-root"
          open={this.props.open}
        >
          <DialogTitle>
            {" "}
            <div className="row">
              <div className="col-10">
                <p className="dialogHeader">File upload</p>
              </div>
              <div className="col-2">
                <IconButton
                  className="closeButtonStyle"
                  onClick={this.props.handleClose}
                >
                  <CustomIcons iconName="close" />
                </IconButton>
              </div>
            </div>{" "}
          </DialogTitle>
          <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <DialogContent className="quickcreate-form">
              <div className="dropzone_block">
                <DropzoneArea
                  key={dropKey}
                  maxFileSize={30000000}
                  showPreviews={false}
                  showPreviewsInDropzone={false}
                  dropzoneText=""
                  showAlerts={false}
                  useChipsForPreview={false}
                  showFileNames
                  previewText=""
                  filesLimit={10}
                  onChange={(files) => this.handleFileData(files)}
                />
                <div className="dropzone_detail">
                  <p className="text_bold">Drag and Drop</p>
                  <p className="text">or</p>
                  <span className={`${this.props.classes.primaryBtn} btn btn-primary`}>Browse</span>
                </div>
              </div>
              <div className="table-section">
                <div className="table-responsive">
                  <table className="custom_Table MuiTable-root">
                    <thead>
                      <tr>
                        <th>File Name</th>
                        <th>Original File Name</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {selected_files && selected_files.length !== 0 ? (
                        <>
                          {selected_files && selected_files.map((item, index) => {
                            return (
                              <tr>
                                <td>
                                  <TextField
                                    {...this.props}
                                    name="disp_name"
                                    hideClear
                                    placeholder="Enter File Name"
                                    required
                                    onChange={this.handleChange.bind(this, index)}
                                    key={index}
                                    value={item.display_name}
                                  />
                                </td>
                                <td>
                                  <p className="text">{item.file.name}</p>
                                </td>
                                <td>
                                  <a href="#" onClick={(e) => this.handleRemoveAttachments(e, item, index)}>
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M2.09375 4H3.42708H14.0938" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M5.42578 3.9987V2.66536C5.42578 2.31174 5.56626 1.9726 5.81631 1.72256C6.06635 1.47251 6.40549 1.33203 6.75912 1.33203H9.42578C9.7794 1.33203 10.1185 1.47251 10.3686 1.72256C10.6186 1.9726 10.7591 2.31174 10.7591 2.66536V3.9987M12.7591 3.9987V13.332C12.7591 13.6857 12.6186 14.0248 12.3686 14.2748C12.1185 14.5249 11.7794 14.6654 11.4258 14.6654H4.75911C4.40549 14.6654 4.06635 14.5249 3.81631 14.2748C3.56626 14.0248 3.42578 13.6857 3.42578 13.332V3.9987H12.7591Z" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                  </a>
                                </td>
                              </tr>
                            )

                          })}

                        </>
                      ) : (
                        <tr>
                          <td colSpan={3}>
                            <div className="empty_Text">
                              <p className="text">Please select files to upload</p>
                            </div>
                          </td>
                        </tr>
                      )}

                    </tbody>
                  </table>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <ButtonSml
                {...this.props}
                type="button"
                onClick={this.props.handleClose}
                className={`${this.props.classes.secondaryBtn} btn btn-secondary`}
                label="Cancel"
                title="Cancel"
              />
              <ButtonSml
                {...this.props}
                disabled={this.state.submit}
                // onClick={this.handleSubmit}
                className={`${this.props.classes.primaryBtn} btn btn-primary`}
                label="Save"
                title="Save"
              />
            </DialogActions>
          </form> {" "}
          <SnackComp snackData={this.state.snackData} handleClose={this.onSnackclose} />

        </BootstrapDialog>
      </>
    );
  }
}
